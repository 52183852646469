import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { hook_Img } from "../../assets/images";
import "./filterComponent.css";
import { useSelector } from "react-redux";

interface FilterProps {
  onProductChange: (selectedProduct: string) => void;
  onTypeofCutChange: (selectedTypeofCut: string) => void;
  onQuantityChange: (selectedQuantity: string) => void;
}

function Filter({
  onProductChange,
  onTypeofCutChange,
  onQuantityChange,
}: FilterProps) {
  const [product, setProduct] = useState("");
  const [typeOfCut, setTypeofCut] = useState("");
  const [quantity, setQuantity] = useState("");
  const { productList } = useSelector((state: any) => state.productDataList);

  const filterUniqueValues = (data: any[] = [], key: string) => {
    if (!data) return [];
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProduct = event.target.value;
    setProduct(selectedProduct);
    onProductChange(selectedProduct);
  };

  const handleTypeofCutChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedTypeofCut = event.target.value;
    setTypeofCut(selectedTypeofCut);
    onTypeofCutChange(selectedTypeofCut);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedQuantity = event.target.value;
    setQuantity(selectedQuantity);
    onQuantityChange(selectedQuantity);
  };

  return (
    <div className="filter-container">
      <div className="headerLine d-flex flex-column flex-md-row align-items-center">
        <div className="col-12 pt-4">
          <span className="headingText text-center text-md-start">
            What’s in <span className="span-with-green">Store</span> for you...
          </span>
          {/* <img src={hook_Img} alt="Hook Img" className="img-fluid" /> */}
        </div>
      </div>
      <div className="col-md-6">
        <div className="row filterButtons text-md-start pt-4">
          {/* <div className="col-4">
            <select
              id="product"
              className="custom-select-filter"
              value={product}
              onChange={handleProductChange}
            >
              <option value="">Product</option>
              <option value="">All</option>
              {filterUniqueValues(productList, "product_name").map(
                (product: any, index: number) => (
                  <option key={`product_${index}`} value={product}>
                    {product}
                  </option>
                )
              )}
            </select>
          </div> */}
          <div className="col-4">
            {/* <label></label> */}
            <select
              id="typeofCut"
              className="custom-select-filter"
              value={typeOfCut}
              onChange={handleTypeofCutChange}
            >
              <option value="">Type of Cut</option>
              <option value="">All</option>
              {filterUniqueValues(productList, "category_name").map(
                (typeOfCut: any, index: number) => (
                  <option key={`typeOfCut_${index}`} value={typeOfCut}>
                    {typeOfCut}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="col-4">
            {/* <label></label> */}
            <select
              id="quantity"
              className="custom-select-filter"
              value={quantity}
              onChange={handleQuantityChange}
            >
              <option value="">Quantity</option>
              <option value="">All</option>
              {filterUniqueValues(productList, "weight").map(
                (quantity: any, index: number) => (
                  <option key={`quantity_${index}`} value={quantity}>
                    {quantity}g
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
