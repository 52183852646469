import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { previousordericon, tuna_fish } from "../../../assets/images";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

interface Order {
  imageUrl: string;
  merchantId: string;
  merchantName: string;
  orderId: string;
  orderStatus: string;
  orderDate: string;
  products?: [
    {
      productName?: string;
      originalPrice?: string;
      quantity?: string;
    }
  ];
  deliveryAddressInfo?: {
    customerAddress?: {
      city?: string;
    };
  };
  productDetails?: [
    {
      quantity: string;
      productName: string;
      subtotal?: string;
    }
  ];
}

const YourOrders = () => {
  const [proceedToPayment, setProceedToPayment] = useState(false);
  const navigate = useNavigate();

  const [yourOrders, setYourOrders] = useState<Order[]>([]);
  const mutation = useMutation<Order[]>(
    "yourOrders",
    async () => {
      try {
        const response = await axiosInstance.post(API_URL.MY_ORDERS, {
          excludeStatus: ["PNI", "PI"],
          index: 0,
          count: 20,
        });
        const yourOrder = response.data.content;
        return yourOrder;
      } catch (error) {
        console.error("Error calling API:", error);
        throw new Error("Error calling API");
      }
    },
    {
      onSuccess: (yourOrder) => {
        setYourOrders(yourOrder);
      },
    }
  );
  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    mutation.mutate();
  }, []);

  const formatDate = (dateTime: string): string => {
    const date = new Date(dateTime);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  const handleOrderNow = () => {
    navigate("/");
  };
  const handleOrderClick = (orderId: string) => {
    navigate(`/OrderDetails`, { state: { orderId } });
  };

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4">
      <div className="row width-full-page pb-5 relative-div-bg">
        <div className="col-md-12 height-custom pt-5">
          <div className="sub-div-headings d-flex pb-3">
            <img src={previousordericon} alt="" />
            &nbsp;
            <span>MY Orders</span>&nbsp;
            <span className="line-span">
              <div className="line-div-max-length-ecommerce"></div>
            </span>
          </div>
          <div className="col-12 profile-minimum-height">
            {yourOrders.length === 0 ? (
              <div className="innerdiv-center">
                <div>Start Your Tasteful Journey</div>
                <div className="inner-text-content-four">
                  No Orders Yet! Order Now
                </div>
                <div className="pt-3">
                  <button
                    className="btn edit-profile-btn px-5"
                    onClick={handleOrderNow}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            ) : (
              yourOrders.map((order, index) => (
                <Card
                  key={index}
                  className="order-details-box-list  mt-2"
                  onClick={() => handleOrderClick(order.orderId)}
                >
                  <CardBody className="p-3">
                    <div className="row">
                      <div className="col-lg-6 align-content-center">
                        <div className="row">
                          <div className="col-6 p-2">
                            <div>
                              <img
                                src={order.imageUrl}
                                alt="order image "
                                className="img-fluid orderImage"
                              />
                            </div>
                          </div>
                          <div className="col-6 align-content-center">
                            <div className="inner-text-heading">
                              {order.merchantName}
                            </div>
                            <div className="inner-text-content-five-font-12">
                              (
                              {order.deliveryAddressInfo?.customerAddress?.city}
                              )
                            </div>
                            <div className="inner-text-content-three">
                              {order.orderStatus}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 pt-2 align-content-center">
                        <table className="table-width">
                          <tbody>
                            <tr className="bottom-border  pt-2 pb-2">
                              <td className="inner-text-content-two span-with-green td-width-half  pt-2 pb-2">
                                {order.orderId}
                              </td>
                              <td className="inner-text-content-five text-end  pt-2 pb-2">
                                {formatDate(order.orderDate)}
                              </td>
                            </tr>
                            {order.productDetails &&
                              order.productDetails.map((product, index) => (
                                <tr className="bottom-border" key={index}>
                                  <td className="inner-text-content-three">
                                    {product.productName}{" "}
                                    <span className="inner-text-content-five-font-12">
                                      ({product.quantity})
                                    </span>
                                  </td>
                                  <td className="inner-text-content-five pt-1 text-end">
                                    ₹{product.subtotal}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourOrders;
