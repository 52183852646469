import React from "react";
import { Card, CardBody } from "reactstrap";
import { previousordericon } from "../../../assets/images";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL, accessToken } from "../../../services/apiConfig";
import "./previousOrder.css";
import { useNavigate } from "react-router-dom";

interface PreviousOrder {
  product_id: string;
  product_name: string;
  product_details: {
    description: string;
    images: string[] | null;
  };
  inventories: {
    [key: string]: {
      price: number;
      available_stock: number;
    };
  } | null;
  weight: number;
}

const PreviousOrders = () => {
  const navigate = useNavigate();
  const access_token = accessToken;

  const { data: previousOrders } = useQuery<PreviousOrder[]>(
    "previousOrders",
    async () => {
      const response = await axiosInstance.get<PreviousOrder[]>(
        `${API_URL.PREVIOUS_ORDERS}`
      );
      return response.data;
    }
  );
  const handleImageClick = (product_id: string) => {
    navigate(`/ProductDetails?id=${product_id}`, { state: { product_id } });
  };

  return (
    <>
      {previousOrders && previousOrders.length > 0 && (
        <>
          <div className="col-md-12 pt-4">
            <div className="sub-div-headings d-flex">
              <img src={previousordericon} alt="" />
              &nbsp;
              <span>Previous Orders</span>&nbsp;
              <span className="line-span">
                <div className="line-div-max-length-ecommerce"></div>
              </span>
            </div>
          </div>
          <div className="col-12 pt-3">
            <div className="row">
              {Array.isArray(previousOrders) &&
                previousOrders.map((order) => (
                  <div
                    className="col-md-2"
                    key={order.product_id}
                    onClick={() => handleImageClick(order.product_id)}
                  >
                    <Card>
                      <CardBody className="p-2">
                        <div>
                          {order?.product_details?.images &&
                          order?.product_details?.images?.length > 0 ? (
                            <img
                              src={order?.product_details?.images[0]}
                              alt={order?.product_name}
                              className="img-previousOrder"
                            />
                          ) : (
                            <img src={previousordericon} alt="" />
                          )}
                        </div>
                        <div>
                          <div className="inner-text-heading-four pt-2">
                            {order.product_name}
                          </div>
                          <div className="inner-text-content-five">
                            Weight: &nbsp;
                            {order?.weight}
                          </div>
                          <div className="inner-text-content-five">
                            Price: &nbsp; ₹
                            {order.inventories &&
                              order.inventories[
                                Object.keys(order.inventories)[0]
                              ]?.price}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PreviousOrders;
