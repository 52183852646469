import React, { useEffect, useState } from "react";
import "./Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  lilo_logo_02,
  play_store,
  app_store,
  instagramicon,
  facebookicon,
  youtubeicon,
  playstore,
  appstoreicon,
  fssailogo,
} from "../../assets/images";
import { Link } from "react-router-dom";
const footerquicklinks = [
  {
    label: "Home",
    value: "Home",
    navlink: "/",
  },
  {
    label: "Fresh Fish",
    value: "Freshfish",
    navlink: "/FreshFish",
  },
  // {
  //   label: "Food",
  //   value: "food",
  //   // navlink: "/Food",
  //   navlink: "/ComingSoon",
  // },
  {
    label: "Poultry",
    value: "Poultry",
    // navlink: "/Food",
    navlink: "/Meat",
  },
  {
    label: "Groceries",
    value: "groceries",
    // navlink: "/Grocery",
    navlink: "/ComingSoon",
  },
  // {
  //   label: "Offers",
  //   value: "offers",
  //   navlink: "/",
  // },
  {
    label: "History",
    value: "history",
    navlink: "/History",
  },
];
const footerlegal = [
  {
    label: "Privacy Policy",
    value: "PrivacyPolicy",
    navlink: "/PrivacyPolicy",
  },
  {
    label: "Terms & Conditions",
    value: "TermsConditions",
    navlink: "/TermsConditions",
  },
  {
    label: "Cookie Policy",
    value: "CookiePolicy",
    navlink: "/CookiePolicy",
  },
  {
    label: "Refund & Cancellation Policy",
    value: "RefundCancellation",
    navlink: "/RefundCancellation",
  },
];
interface FooterProps {
  currentPage: string;
  setCurrentPage: (page: string) => void;
  footerSelectPage?: string;
}
const Footer: React.FC<FooterProps> = ({ currentPage, setCurrentPage }) => {
  const [selecetedNav, setSelecetdNav] = useState("Home");

  const handleSetActiveNavigation = (nav: string) => {
    setSelecetdNav(nav);
    setCurrentPage(nav);
  };

  useEffect(() => {
    setSelecetdNav(currentPage);
  }, [currentPage]);
  return (
    <div className="footer-container">
      <div className="container-fluid footer-container d-flex justify-content-center text-start pb-4">
        <div className="row list-row width-full-page pt-4">
          <div className="col-md-4 pt-3">
            <div>
              <img
                src={lilo_logo_02}
                alt="Logo"
                className="img-fluid footer-logo"
              />
            </div>
            <div className="footer-text d-flex align-items-center pt-2">
              <img src={fssailogo} alt="" className="fssailogo img-fluid" />
              <span className="ps-2">Lic. No. 11324999000396</span>
            </div>
          </div>
          <div className="col-md-4 my-custom-margin pt-3">
            <div className="row">
              <div className="col-6">
                <ul className="list">
                  <li>
                    <h5 className="footer-heading-links">Quick Links</h5>
                  </li>
                  {footerquicklinks.map((item) => (
                    <li onClick={() => handleSetActiveNavigation(item.value)}>
                      <Link
                        className={` footer-link ${
                          selecetedNav === item.value ? "footeractive" : ""
                        }`}
                        to={item.navlink}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-6">
                <ul className="list">
                  <li>
                    <h5 className="footer-heading-links">Legal</h5>
                  </li>
                  {footerlegal.map((item) => (
                    <li onClick={() => handleSetActiveNavigation(item.value)}>
                      <Link
                        className={` footer-link ${
                          selecetedNav === item.value ? "footeractive" : ""
                        }`}
                        to={item.navlink}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-custom-margin pt-3">
            <div className="footer-text-right">Subscribe to our Newsletter</div>
            <div className="input-group mb-3 pt-4">
              <input
                type="text"
                className="form-control input-footer"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button className="btn subscribe-button" type="button">
                  Subscribe
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Link to="https://play.google.com/store/apps/details?id=com.livelocal.consumerapp">
                  <button className=" footer-btn-app-link mt-3">
                    <img src={playstore} alt="" />
                    <span className="">&nbsp;Google Play</span>
                  </button>
                </Link>
              </div>
              {/* &nbsp;&nbsp; */}
              <div className="col-lg-6">
                <Link to="https://apps.apple.com/in/app/lilo-livelocal/id6473018597">
                  <button className=" footer-btn-app-link mt-3">
                    <img src={appstoreicon} alt="" />
                    <span className="">&nbsp;App Store</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4 d-flex justify-content-center footer-credit-text-div px-2">
        <div className="width-full-page text-center footer-credit-text pb-4 d-flex">
          <div className="col text-start">©2024 | All rights reserved</div>
          <div className="col text-end">
            <Link to="https://www.instagram.com/lilo_india" className="px-2">
              <img src={instagramicon} alt="" className="img-fluid" />
            </Link>
            <Link to="#" className="px-2">
              <img src={facebookicon} alt="" className="img-fluid" />
            </Link>
            <Link to="https://www.youtube.com/@Live_Local" className="px-2">
              <img src={youtubeicon} alt="" className="img-fluid" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
