// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon-text-head {
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 34px;
  color: #28af4b;
  animation: bounce 2.3s infinite ease-in 0.1s;
}

.coming-soon-text-content {
  font-family: "Poppins", sans-serif;
}

.btn-coming-soon {
  width: 175px;
  border: 1px solid #28af4b;
  color: #28af4b;
  font-family: "Poppins", sans-serif;
}

.btn-coming-soon:hover {
  border: 1px solid #28af4b;
  background-color: #28af4b;
  color: white;
}

@media (min-width: 500px) {
  .btn-div-text-start {
    text-align: start;
  }
  .btn-div-text-end {
    text-align: end;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ComingSoon/coming-soon.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,4CAA4C;AAC9C;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".coming-soon-text-head {\n  font-family: \"Nunito\", sans-serif;\n  font-weight: 800;\n  font-size: 34px;\n  color: #28af4b;\n  animation: bounce 2.3s infinite ease-in 0.1s;\n}\n\n.coming-soon-text-content {\n  font-family: \"Poppins\", sans-serif;\n}\n\n.btn-coming-soon {\n  width: 175px;\n  border: 1px solid #28af4b;\n  color: #28af4b;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.btn-coming-soon:hover {\n  border: 1px solid #28af4b;\n  background-color: #28af4b;\n  color: white;\n}\n\n@media (min-width: 500px) {\n  .btn-div-text-start {\n    text-align: start;\n  }\n  .btn-div-text-end {\n    text-align: end;\n  }\n}\n\n@keyframes bounce {\n  0% {\n    transform: scale(1);\n  }\n  25% {\n    transform: scale(1.15);\n  }\n  50% {\n    transform: scale(1);\n  }\n  75% {\n    transform: scale(1.15);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
