import React, { useEffect, useState } from "react";
import {
  CategoryImageicon,
  SpecialOfferIcon,
  fish_thorny,
  homepagefishimg,
} from "../../../assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
interface Product {
  out_of_stock: any;
  vendor_product_inventories: any;
  weight: string;
  product_id: string;
  product_name: string;
  category_name: string;
  product_details?: {
    images?: string[];
  };
  variants?: {
    price?: number;
  }[];
}

const Favourites = () => {
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 932 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 932, min: 568 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 2,
    },
  };

  const { longitude, latitude } = useSelector(
    (state: any) => state.location.mapRegion
  );

  const { data: fishProducts } = useQuery({
    queryKey: ["specialOffers", longitude, latitude], // Add location as dependencies
    queryFn: async () => {
      if (longitude && latitude) {
        const response = await axiosInstance.get(
          `${API_URL.FAVOURITES_LIST}?customerLongitude=${longitude}&customerLatitude=${latitude}`,
          { headers: { version: "v2" } }
        );
        return response.data;
      }
      return null;
    },
    enabled: !!longitude && !!latitude, // Only run query if location is available
  });

  const allFishProducts = fishProducts?.content || [];

  const handleImageClick = (product_id: string) => {
    navigate(`/ProductDetails?id=${product_id}`, { state: { product_id } });
  };

  return (
    <>
      {allFishProducts && allFishProducts.length > 0 && (
        <div className="col-md-12 pt-1">
          <div className="">
            <div className="sub-div-headings d-flex">
              <img src={SpecialOfferIcon} alt="" />
              &nbsp;
              <span>Favourites</span>&nbsp;
              <span className="line-span">
                <div className="line-div-max-length-ecommerce"></div>
              </span>
            </div>
            <div className="col-md-12 cart-container div-caroysel-relative  pt-4">
              <div className="row pt-3">
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  rewind={true}
                >
                  {/* maping start  */}
                  {allFishProducts?.map((product: Product) => (
                    <div
                      className="pt-2 mb-3 content-fishcard fish-card-div-height px-2"
                      key={product.product_id}
                    >
                      {/* <div className="card stock-card card-no-border card-hghlght-reverse"> */}
                      <div
                        className={`card   ${
                          product.out_of_stock
                            ? "stock-card-out-of-stock"
                            : "stock-card card-hghlght-reverse product-card-cat"
                        }`}
                      >
                        <div className="card-body ">
                          <div className="card-img-div-top">
                            <img
                              className={`card-img-top ${
                                product.out_of_stock
                                  ? "out-of-stock desaturate"
                                  : ""
                              }`}
                              src={
                                product?.product_details?.images?.[0] ??
                                fish_thorny
                              }
                              alt=""
                              onClick={() =>
                                handleImageClick(product?.product_id)
                              }
                            />
                          </div>
                          <div className="p-2">
                            <div className="inner-text-heading-four pt-2 one-line-headings">
                              {product?.product_name}
                            </div>
                            <div className="inner-text-content-five-font-12">
                              <div className="">{product?.category_name}</div>

                              <span className="">{product?.weight}gm</span>
                              <span>&nbsp;|&nbsp;</span>
                              <span className="font-color-gray">8-10 pcs</span>
                            </div>
                          </div>
                          {product.out_of_stock ? (
                            <div className="out-of-stock-text pt-4">
                              <button className=" outofstock">
                                Out of Stock
                              </button>
                            </div>
                          ) : (
                            <>
                              <div className="inner-text-content-five pt-1 ps-2">
                                Price :
                                <span className="inner-text-heading-four">
                                  {" "}
                                  ₹
                                  {product?.vendor_product_inventories?.[0]
                                    ?.price ?? 0}
                                </span>
                              </div>
                              <div className="mb-0 text-end">
                                <button
                                  className=" add-to-cart-button "
                                  onClick={() =>
                                    handleImageClick(product?.product_id)
                                  }
                                >
                                  View
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Favourites;
