import React, { useState } from "react";
import "./homepage.css";
import {
  Bestpickersicon,
  CategoryImageicon,
  companyicons,
  homepagefishimg,
  image_home_page,
  leaf_image_small,
  previousordericon,
} from "../../assets/images";
import {
  BestPicks,
  DryFishhome,
  Favourites,
  FreshFishhome,
  PreviousOrders,
} from "../../components";

const HomePage = () => {
  const [id, setId] = useState("");
  const accessToken = localStorage.getItem("accessToken");

  const generateID = () => {
    const randomNumber = Math.floor(Math.random() * 10000);
    const paddedNumber = randomNumber.toString().padStart(3, "0");
    setId(paddedNumber);
  };

  return (
    <>
      <div className="col-12 d-flex justify-content-center text-start pt-5">
        <div className="row width-full-page pb-2 relative-div-bg">
          <div className="absolute-div-home">
            <div className="absolute-home-div-img" />
          </div>
          <div className="absolute-side-div-img" />
          <div className="absolute-img-div-leaf-choos">
            <img src={leaf_image_small} alt="" className="img-leaf-choos" />
          </div>
          {/* ----- first div -------- */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-8 align-content-center   relative-div-bg">
                <div className="absolute-img-div-leaf-home-2">
                  <img
                    src={leaf_image_small}
                    alt=""
                    className="img-leaf-home-2"
                  />
                </div>
                <div className="carousel-heading pt-4">
                  Dive into the extraordinary world of seafood with{" "}
                  <span className="span-with-green">LILO</span>
                </div>
                <div className="carousel-inner-text  pt-4">
                  Experience{" "}
                  <span className="span-with-green">
                    LILO the freshest, sustainably sourced seafood{" "}
                  </span>
                  delivered right to your doorstep with our LILO{" "}
                  <span className="span-with-green">
                    eco-conscious platform.
                  </span>
                </div>
                <div></div>
              </div>
              <div className="col-4 align-content-center">
                <img src={image_home_page} alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          {/* <div>
            <button onClick={generateID}>Generate ID</button>
            <p>ID: {id}</p>
          </div> */}

          <FreshFishhome />
          {/* <BestPicks /> */}
          <DryFishhome />
          <Favourites />

          {accessToken && <PreviousOrders />}
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center text-start bg-home-bottom-div p-1 ">
        <div className="row width-full-page justify-content-center relative-div-bg">
          <img
            src={companyicons}
            alt=""
            className="img-fluid img-home-bottom"
          />
        </div>
      </div>
    </>
  );
};

export default HomePage;
