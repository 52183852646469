// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-freshfish-carousel {
  border: 0px solid transparent;
}
.card-freshfish-carousel:hover {
  background-image: radial-gradient(
    circle at center,
    #8cffa3,
    transparent,
    transparent
  );
}
.div-caroysel-relative {
  position: relative;
}
.div-caroysel-relative {
  .react-multiple-carousel__arrow--left {
    right: 45px;
    left: auto;
    top: 0;
    z-index: 2;
    border: 0.5px solid #00b523;
    background: transparent;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    padding: 0;
  }
  .react-multiple-carousel__arrow--right {
    right: 10px;
    left: auto;
    top: 0;
    z-index: 2;
    border: 0.5px solid #00b523;
    background: transparent;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    padding: 0;
  }

  .react-multi-carousel-list {
    position: unset;
  }
  .react-multiple-carousel__arrow::before {
    color: #00b523;
  }
}

.img-freshfish-carousel {
  width: 100%;
  aspect-ratio: 1/1;
  /* border-radius: 50%; */
  max-height: 100px;
  max-width: 100px;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomePage/FreshFish/freshfish.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE;;;;;GAKC;AACH;AACA;EACE,kBAAkB;AACpB;AACA;EACE;IACE,WAAW;IACX,UAAU;IACV,MAAM;IACN,UAAU;IACV,2BAA2B;IAC3B,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;EACZ;EACA;IACE,WAAW;IACX,UAAU;IACV,MAAM;IACN,UAAU;IACV,2BAA2B;IAC3B,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,eAAe;EACjB;EACA;IACE,cAAc;EAChB;AACF;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,wBAAwB;EACxB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".card-freshfish-carousel {\n  border: 0px solid transparent;\n}\n.card-freshfish-carousel:hover {\n  background-image: radial-gradient(\n    circle at center,\n    #8cffa3,\n    transparent,\n    transparent\n  );\n}\n.div-caroysel-relative {\n  position: relative;\n}\n.div-caroysel-relative {\n  .react-multiple-carousel__arrow--left {\n    right: 45px;\n    left: auto;\n    top: 0;\n    z-index: 2;\n    border: 0.5px solid #00b523;\n    background: transparent;\n    min-width: 30px;\n    min-height: 30px;\n    border-radius: 50%;\n    padding: 0;\n  }\n  .react-multiple-carousel__arrow--right {\n    right: 10px;\n    left: auto;\n    top: 0;\n    z-index: 2;\n    border: 0.5px solid #00b523;\n    background: transparent;\n    min-width: 30px;\n    min-height: 30px;\n    border-radius: 50%;\n    padding: 0;\n  }\n\n  .react-multi-carousel-list {\n    position: unset;\n  }\n  .react-multiple-carousel__arrow::before {\n    color: #00b523;\n  }\n}\n\n.img-freshfish-carousel {\n  width: 100%;\n  aspect-ratio: 1/1;\n  /* border-radius: 50%; */\n  max-height: 100px;\n  max-width: 100px;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
